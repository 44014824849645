import retrieveLocalData from "./retrieveLocalData";
import storeLocalData from "./storeLocalData";

export default (
    destinationId, {
      orderNum, customerNum
    }
  ) => {
    if (!destinationId || !orderNum || !customerNum) {
      console.log("Cannot update destinationOrderCustomer. destinationId, orderNum and customerNum are required. Destination id: ", destinationId);
      return;
    }

    // Use cache as basis
    let destinationOrderCustomer = {
      ...retrieveLocalData("destinationOrderCustomer")
    };

    let orderCustomerItems = destinationOrderCustomer[destinationId] || [];
    if (!orderCustomerItems.find(obj => obj.orderNum === orderNum)) {
      orderCustomerItems.push({
        "orderNum": orderNum,
        "customerNum": customerNum
      })
    }

    destinationOrderCustomer[destinationId] = orderCustomerItems;

    storeLocalData("destinationOrderCustomer", destinationOrderCustomer);
};

