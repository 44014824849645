import gql from "graphql-tag";
import get from "lodash/get";

export const getOrderQuery = gql`
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      id
      orderNum
      orderDate
      customerNum
      customerName
      customerData {
        id
        name
        contactPersons {
          phone
          email
          fax
        }
        businessId
        address
        zipCode
        city
        phone
        email
      }
      contractorData {
        id
        name
        phone
        email
        businessId
        company
        address
        zipCode
        city
      }
      deliveryCondition
      payerNum
      payerName
      customerOrderNum
      customerRefNum
      orderRefNum
      additionalInfo
      delivery
      rows {
        commodityName
        manualItemName
        orderedAmount
        orderedUnit
        transportAmount
        transportUnit
        weight
        items
        length
        width
        height
        additionalInfo
        adrInfo {
          packagingMethodId
          packagingMethod
          packagingGroupId
          packagingGroup
          calculatedValue
          tunnelCode
          adrCode
          adrTitle
          hazardClassId
          hazardClass
          subRisk1Id
          subRisk1
          subRisk2Id
          subRisk2
          subRisk3Id
          subRisk3
          netAmount
          netUnitId
          netUnit
          specialProvision
        }
        wasteInfo {
          wasteCode
          wasteTitle
          properties
          wayOfHandling
          stateOfMatter
          transportMethod
          wasteType
          hazardousProperties
          packingMethod
          popCode
          codeOfOrigin
          recoveryCode
          disposalCode
          section21355waste
          wasteProducer
          address
          zipCode
          phone
          email
        }
        transactions {
          contractorId
          status
          vehicleId
          waybillNum
          routeId
          sender
          pickupTransactionId
          pickupLocation
          pickupAddress
          pickupAddressAdditionalInfo
          pickupZipcode
          pickupCity
          pickupDoor
          pickupHours
          pickupCoordinatesX
          pickupCoordinatesY
          pickupPerson
          pickupPhone
          pickupEmail
          orderedPickupStartTime
          orderedPickupEndTime
          orderedUnloadStartTime
          orderedUnloadEndTime
          plannedPickupStartTime
          plannedPickupEndTime
          plannedUnloadStartTime
          plannedUnloadEndTime
          routePlannedPickupStartTime
          routePlannedPickupEndTime
          routePlannedUnloadStartTime
          routePlannedUnloadEndTime
          receiver
          unloadTransactionId
          unloadLocation
          unloadAddress
          unloadAddressAdditionalInfo
          unloadZipcode
          unloadCity
          unloadDoor
          unloadHours
          unloadCoordinatesX
          unloadCoordinatesY
          unloadPerson
          unloadPhone
          unloadEmail
          orderedAmount
          orderedUnit
          actualUnloadEndTime
          actualAmount
          actualUnit
          actualPickupStartTime
          actualPickupEndTime
          actualUnloadStartTime
        }
      }
    }
  }
`;

// This is a legacy function that needs removed, it was originally here when all data was passed in as a string.
// Now the API returns a ful GraphQL response so this can be removed from teh codebase.
// I am leaving this here whilst testing the response to avoid breaking anything in the codebase.
export const getOrderProps = (data = { getOrder: {} }) => {
  try {
    const order = get(data, "getOrder", {});
    return order;
  } catch (e) {
    console.log("Error parsing props:", e);
    return null;
  }
};
